import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CommonService } from '../../../common.service';
import { OrganizationService } from '../../organization-service.service';
import { Router } from '@angular/router';
import { PermissionService } from '../../../permission.service';

@Component({
  selector: 'app-organization-new',
  templateUrl: './organization-new.component.html',
  styleUrls: ['./organization-new.component.css']
})
export class OrganizationNewComponent   {
  private _baseHref: string;
  tenantNames = ['Deloitte Global', 'Swiss','BDH']; 
  BDHtenants: any[] = [];
  bdhTenantName;
  selectedBdhTenant: string = '';

  private _token;
  organizationRequest: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(/^[^>+\:\;|\/*^$%@\(\)\{\}\[\]!\\~=#*]+$/), ]),
    description: new FormControl(''),
    emailLinkValidityDurationHours: new FormControl('', [Validators.minLength(1)]),
    contactEmail: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9._%-\'. \-_!#^~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/), ]),
    emailOtp: new FormControl(null),
    status: new FormControl(''),
    featureflag1: new FormControl(null),
    featureflag2: new FormControl(null),
    featureflag3: new FormControl(null),
    featureflag4: new FormControl(null),
    featureflag5: new FormControl(null),
    featureflag6: new FormControl(null),
    sendActivationEmail: new FormControl(false),
    tenantName: new FormControl(null,[Validators.required])
  });
  alerts = [];
  selectedOrganizations: any;
  alert = false;
  filteredOptions: Observable<[]>;
  BDHFlag: boolean=false;
  constructor(private fb: FormBuilder,private organizationService: OrganizationService, private router: Router, private permission: PermissionService) {
  }
  public get baseHref(): string {
    return this._baseHref;
  }
  public set baseHref(value: string) {
    this._baseHref = value;
  }
  public get token() {
    return this._token;
  }
  public set token(value) {
    this._token = value;
  }
  gotoOrganizationListPage()
  {
    this.router.navigate(['organization/list']);
  }
  authorized() {
    return this.permission.isAdmin ;
  }
  authorizedActivationParam()
  {
    return this.permission.isAdmin;
  }
  authorizedEmailOtpParam()
  {
    if(this.organizationRequest.get('tenantName')?.value !=null)
    return this.permission.isAdmin && this.organizationRequest.get('tenantName')?.value.toLowerCase() != 'swiss';
    else
    return this.permission.isAdmin
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  ngOnInit(): void {
    this.organizationService.getTenants().subscribe(data => {
      this.BDHtenants = data.tenants;
    });
    this.organizationRequest.get('tenantName')?.valueChanges.subscribe(selectedTenant => {
      this.onTenantNameChange(selectedTenant);
    });
  }
  
  onTenantNameChange(selectedTenant): void {
    if (selectedTenant === 'BDH') {
      this.BDHFlag=true;
      this.organizationService.getTenants().subscribe(data => {
        this.BDHtenants = data.tenants;
      });  
      this.organizationRequest.addControl('bdhTenant', this.fb.control('', Validators.required));

      } else {
        this.BDHFlag=false;
        this.organizationRequest.removeControl('bdhTenant');
      this.BDHtenants = [];
    }
  }
  submit()
  {
    if( this.organizationRequest.get('tenantName').value === 'Deloitte Global')
    {
      this.organizationRequest.get('tenantName').setValue("global");
    }
    else if( this.organizationRequest.get('tenantName').value === 'BDH')
      {
        const selectedBdhTenant = this.organizationRequest.get('bdhTenant')?.value;
        this.organizationRequest.get('tenantName').setValue(selectedBdhTenant);
        this.organizationRequest.removeControl('bdhTenant');
      }
    else
    {
      this.organizationRequest.get('tenantName').setValue(this.organizationRequest.get('tenantName').value.toLowerCase());
    }
    const formValue = { ...this.organizationRequest.value };
    Object.keys(formValue).forEach(key => {
      if (formValue[key] === null&&key!="emailOtp") {
        formValue[key] = ''; // Replace null with an empty string
      }
    });

    this.organizationRequest.get('status').setValue("active");
    this.organizationService.saveOrganization(formValue).subscribe(data => {
      this.router.navigate(['organization/list']);
    },err =>
      {
        this.alerts.push({ type: 'danger', msg:"Unable to save org" });
      }
  );
  }
  numericOnly(event): boolean {
    // Allow key codes for special events. Backspace = 8, Delete = 46, Arrow keys = 37-40, and others as needed
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (allowedKeys.includes(event.key)) return; // Allow navigation and correction keys
    const charCode = event.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) { // Check if the key is not a digit
      event.preventDefault(); // Prevent the key input
    } else {
      // Check if the resulting value will be greater than 1
      const nextValue = this.organizationRequest.value.emailLinkValidityDurationHours + event.key;
      if (parseInt(nextValue, 10) < 1) {
        event.preventDefault(); // Prevent the input if the value is not greater than 1
      }
    }
  }
 
  onSubmit(organizationRequest)
  {

  }
}
